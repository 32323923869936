import "./List.css"

type Props = {
    items: JSX.Element[];
}

const List = ({items}: Props) => {
    return (
        <ul>
            {items.map((elem) => <li>{elem}</li>)}
        </ul>
    )
}

export default List;