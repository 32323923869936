import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p>made with ❤️ in miami</p>
    </div>
  );
}

export default Footer;

