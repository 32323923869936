import React from "react";
import "./Bio.css"
import Underlined from "../Underlined/Underlined";
import List from "../List/List";


const PreviousListElements: JSX.Element[] = [
    <span>Launched <Underlined text="Campfire" link="https://campfire.nianticlabs.com/en/"/> while at <Underlined text="Niantic" link="https://nianticlabs.com/?hl=en"/> and created an LLM based UI Automation platform </span>,
    <span>Worked on some internal tooling at <Underlined text="AWS" link="https://aws.amazon.com/"/> under the <Underlined text="Forecast" link="https://aws.amazon.com/forecast/"/> team </span>,
    <span>Developed large scale (~1.5 million trades per second) stock options viewing platform for <Underlined text="OptionsSwing" link="https://www.optionsswing.com/"/> </span>,
    <span>Improved discord community membership management platform at <Underlined text="Ternary Developments" link="https://www.ternarydev.com/"/> </span>,
    <span>Published a paper on using LSTMs to predict hotel demand with the <Underlined text="University of Florida" link="https://www.ufl.edu/"/> </span>,
    <span>Founded a botting startup specializing in iOS solutions for high-traffic retail sites </span>
]

const Bio = () => {
    return (
        <div className="root">
            Hey there!
            I am a rising Junior studying CS with a concentration in Computer Graphics at <Underlined text="CMU" link="https://www.cmu.edu/"/>. 
            Broadly interested in startups, ML applications, and building useful products.
            <br />
            <br />
            <span className="previously">Previously:</span> 
            <br />
            <List items={PreviousListElements} />
        </div>
    )
}
export default Bio;