import "./Underlined.css"

type Props = {
    text: string;
    link: string;
}

const Underlined = ({text, link}: Props) => {
    return <a href={link} target="_blank" rel="noreferrer" ><span className="underlined">{text}</span></a>
}

export default Underlined;