import React from 'react';
import { LinkedinFilled, GithubFilled, MailOutlined } from '@ant-design/icons';
import "./Header.css"

const Header = () => {
  return (
    <header>
      <div>David Krajewski</div>
      <div>
        <a href="https://www.linkedin.com/in/david-krajewski-06098b57/" target="_blank" rel="noopener noreferrer">
          <LinkedinFilled/>
        </a>
        <a href="https://github.com/kraj011" target="_blank" rel="noopener noreferrer">
          <GithubFilled />
        </a>
        <a href="mailto:contact@davidkrajewski.com">
          <MailOutlined />
        </a>

      </div>
    </header>
  );
};

export default Header;

