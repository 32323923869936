import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
// import myPicture from './images/me.jpg'; // replace with your picture file
import Header from './components/Header/Header';
import Bio from './components/Bio/Bio';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>David Krajewski</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="title" property="title" content="David Krajewski" />
        <meta name="description" property="description" content="David's Portfolio" />
        <meta name="keywords" content="David Krajewski, CMU, Software Developer, React, Machine Learning, Startups" />
      </Helmet>


      <Header />
      <Bio />
      <Footer />
    </div>
  );
}

export default App;

